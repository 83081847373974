.App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set the height of the container to full viewport height */
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    /* width: 100vw;
     height: 100vh; */
    background-image: url('./cloud-background.jpg');
    background-size: cover;
  }
  
  .logo-container {
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .logo {
    width: 200px; /* Adjust width as needed */
    height: auto;
  }
  .video-container {
  border-radius: 20px; 
  width: 100%;  
  height: 100%;  
   
  /* background-color: black;  */
  }
  .main-video-container{
    border-radius: 20px; 
    background-color: black; 
    
  }
  .audio-player-container {
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Align items horizontally at the center */
    align-items: center; /* Align items vertically at the center */
    /* Make the container full height */
     height: 100px;
  }
  
/*   
  .video {
    display: block;
    width: 100%;
    border-radius: 20px; 

  } */
  
/* Styles for custom buttons */
.custom-buttons {
  position: absolute;
  bottom: 1px;
  /* left: 50%; */
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .round-button {
  width: 50px;  
  height: 50px;  
  border-radius: 50%;  
  background-image: url('./demo-7.jpg'); 
}

.button-text {
   font-size: 16px;  
  font-weight: bold; 
  margin: 5px;
  text-align: center;

}
.button-container {
  text-align: center; 
}

.round-button:hover {
  opacity: 0.8;  
}
   */

.round-button {
  
  border-radius: 50%;
  background-color: #000000; /* Set button color */
  margin: 10px; /* Add spacing between buttons */
}

/* Button text styles */
.button-text {
  color: #fff; /* Set text color */
  font-size: large;
  text-align: center;
}

/* Countdown timer styles */
.countdown-timer {
  margin-top: 20px; /* Add spacing between buttons and timer */
  color: #000; /* Set timer text color */
  font-size: 16px;
}
.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color:#6610f2; /* Change color as needed */
  margin: 0 auto 5px; /* Margin bottom to create space for the text */
}
.rounded-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttons-row {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line */
  justify-content: center; /* Align buttons in the center horizontally */
}
.btt-text{
  display: block;
  margin: 5px;
  color: white;
  white-space: nowrap;
  font-size: medium;
}
